// 统一请求路径前缀在libs/axios.js中修改
import {
  getNoAuthRequest,
  postNoAuthRequest,
  getRequest,
  postRequest,
  // putRequest,
  // postBodyRequest,
} from "@/libs/axios";
// import { setStore } from "@/libs/storage";
// import { getApp } from "@/main"

// 官网会员登录信息JWT Key
export const token_name = process.env.VUE_APP_JWT_TOKEN;
// Vaptcha ID
export const vaptchaID = "";
// 验证码渲染图片接口
export const drawCodeImage = `${process.env.VUE_APP_BACKEND_URL}/common/captcha/draw/`;

export const getMsgCenterConnToken = (user, exp) => {
  // 获取MessageCenter连接Token
  return postRequest(`/weixin/getMsgCenterConnToken`, {
    user,
    exp,
  });
};
export const getMsgCenterChannelToken = (channel, user, exp) => {
  // 获取MessageCenter频道订阅Token
  return postRequest(`/weixin/getMsgCenterChannelToken`, {
    channel,
    user,
    exp,
  });
};

export const getLoginQRCode = () => {
  return getNoAuthRequest("/weixin/login_qrcode");
};

export const getPayQRCode = (oldLicenseId, productSpecId, channelCode, userAccountID, openId) => {
  return postRequest("/weixin/pay_qrcode", { oldLicenseId, productSpecId, channelCode, userAccountID, openId });
};

export const getProductSpec = () => {
  return getNoAuthRequest("/product-spec");
};

export const getChannelProductSpec = (channelCode) => {
  return postNoAuthRequest("/channel-product-spec", { channelCode });
};

export const getUserLicense = () => {
  return getRequest("/user-license", {});
};

export const getUserOrder = () => {
  return getRequest("/user-order", {});
};

export const getChannelDrawRequest = () => {
  return getRequest("/channel-draw-request", {});
};

export const getChannelInfo = () => {
  return getRequest("/channel-info", {});
};

export const createDrawRequest = () => {
  return getRequest("/create-draw-request", {});
};
