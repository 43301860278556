import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
// 定义全局参数
Vue.prototype.$globalParam = {
  ConsultQQ: "3795662001",
  DownloadURL: process.env.VUE_APP_DOWNLOAD_URL,
};

const app = new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    console.log("App created")
    // 将Vue实例设置为全局属性
    Vue.prototype.$app = this;
    // 在从缓存加载存储项
    this.$store.commit("loadUserStateFromStorage");
  }
});

app.$mount('#app')

// 在其他模块中获取app实例
export function getApp() {
  // 使用Vue的原型链访问app实例
  return Vue.prototype.$app;
}