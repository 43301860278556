export const maskPhoneNumber = (phoneNumber) => {
  // 将中间的数字替换为星号
  return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
}

// 加载外部JS
export const loadExternalJs = ()=>{
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "assets/js/hostom.js?v=1";
  document.getElementsByTagName("head")[0].appendChild(script);
}