<template>
  <router-view />
</template>

<script>
export default {
  mounted() {
    console.log("App mounted")
  },
  methods: {},
};
</script>
