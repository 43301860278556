<template>
  <div>
    <MainHeader></MainHeader>
    <!-- hero-fore section start -->
    <section class="hero-fore">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="hero-fore__content">
              <h2
                class="hero-fore__title wow fadeInLeft"
                data-wow-duration="1000ms"
                data-wow-delay="900ms"
              >
                <span>《小智助播》</span>
                <div>智能跟播系统</div>
              </h2>
              <h6
                class="hero-fore__sub__title wow fadeInLeft mt-3"
                data-wow-duration="1000ms"
                data-wow-delay="800ms"
              >
                通过语音自动识别主播所讲的话，对应播放提前录制好的语音内容（场控话术）、切换相应产品提词卡，从而解放场控岗位相关工作，为直播间节省成本。
              </h6>
              <div
                class="hero-fore__btn wow fadeInLeft"
                data-wow-duration="1000ms"
                data-wow-delay="1000ms"
              >
                <a class="hostom-btn" :href="DownloadURL" target="_blank" @click="download">立即下载</a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="hero-fore__right wow fadeInRight"
              data-wow-duration="1000ms"
              data-wow-delay="800ms"
            >
              <div class="hero-fore__thumb hero-fore__thumb--one">
                <img
                  src="assets/images/resources/hero-4-1.png"
                  alt="hostom image"
                />
                <div class="hero-fore__thumb__shape">
                  <img
                    src="assets/images/shapes/hero-4-shape.png"
                    alt="shape"
                  />
                </div>
              </div>
              <div class="hero-fore__thumb hero-fore__thumb--two">
                <img
                  src="assets/images/resources/hero-4-2.png"
                  alt="hostom image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hero-fore__shape">
        <img src="assets/images/shapes/hero-shape-4-2.png" alt />
      </div>
    </section>
    <!--  hero-fore section start -->

    <!-- domain-name-two section start -->
    <section class="domain-name-two">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="domain-name-two__left">
              <div class="domain-name-two__inner">
                <div class="domain-name-two__form">
                  <div class="domain-name-two__form__item">
                    <input
                      type="text"
                      name="channelCode"
                      v-model="channelCode"
                      placeholder="输入优惠码价格更优惠"
                    />
                    <span class="hostom-btn" @click="queryChannelProductSpec"
                      >查询</span
                    >
                  </div>
                </div>
                <ul class="list-unstyled domain-name-two__list">
                  <li
                    class="domain-name-two__list__item"
                    v-for="(item, i) in productSpecList"
                    :key="i"
                  >
                    <div
                      :class="
                        'product-card ' +
                        (selectedProductId == item.id ? 'selected-product' : '')
                      "
                      @click.stop="selectProduct"
                      :data-id="item.id"
                    >
                      <span :data-id="item.id">{{ item.productSpec }}</span>
                      <div
                        v-if="item.channelPrice"
                        class="retail-price price"
                        :data-id="item.id"
                      >
                        ￥{{ item.retailPrice }}
                      </div>
                      <div class="price" :data-id="item.id">
                        ￥{{
                          item.channelPrice
                            ? item.channelPrice
                            : item.retailPrice
                        }}
                      </div>
                    </div>
                  </li>
                </ul>
                <div class="col-lg-12 mt-20 flex-center">
                  <div
                    class="hostom-btn hostom-btn--black"
                    @click.stop="showPayQRCode"
                  >
                    立即下单
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="domain-name-two__right fs-5 mt-50">
              <div>直播间减少场控人员，每月节省<b>6000元</b>，香不香？</div>
              <div>直播间达到<b>10个场控</b>配合主播的效果，香不香？</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- domain-name-two section end -->

    <!-- service-fore section start -->
    <section class="service-fore">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul
              class="service-fore__inner list-unstyled wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="800ms"
            >
              <li class="service-fore__inner__item">
                <div class="service-fore__inner__content">
                  <div class="service-fore__inner__icon">
                    <i class="fa fal fa-donate"></i>
                  </div>
                  <h4 class="service-fore__inner__title">节省直播间盯播人力</h4>
                  <p class="service-fore__inner__text">
                    不再需要专门的场控或助播人员。《小智助播》通过主播的直播进行自动语音识别，就可以播放场控录音。
                  </p>
                </div>
              </li>
              <li class="service-fore__inner__item">
                <div class="service-fore__inner__content">
                  <div class="service-fore__inner__icon">
                    <i class="fa fal fa-plane-departure"></i>
                  </div>
                  <h4 class="service-fore__inner__title">提升直播间场控质量</h4>
                  <p class="service-fore__inner__text">
                    场控话术提前录制，确保话术准确；可多人录制，营造直播间更好氛围。
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="service-fore__shape">
        <img src="assets/images/shapes/service-4.png" alt />
      </div>
    </section>
    <!-- service-fore section end -->

    <!-- benefits-fore section start -->
    <section class="benefits-fore">
      <div class="container">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div
                class="sec-title text-center sec-title--two wow fadeInUp"
                data-wow-duration="700ms"
              >
                <!-- /.sec-title__tagline -->
                <h3 class="sec-title__title">降本增效 · 只须三步</h3>
                <!-- /.sec-title__title -->
              </div>
              <!-- /.sec-title -->
            </div>
          </div>
        </div>
        <div class="benefits-fore__inner">
          <div class="row">
            <div class="col-lg-6">
              <div class="benefits-fore__left">
                <div class="tabs-box">
                  <div
                    class="benefits-fore__left__tab wow fadeInUp"
                    data-wow-duration="1500ms"
                  >
                    <div class="benefits-fore__left__tab__link tab-link">
                      <button
                        data-tab="#add"
                        class="benefits-fore__left__tab__link__item tab-btn active-btn"
                      >
                        1. 注册
                      </button>
                      <button
                        data-tab="#market"
                        class="benefits-fore__left__tab__link__item tab-btn"
                      >
                        2. 下载
                      </button>
                      <button
                        data-tab="#plan"
                        class="benefits-fore__left__tab__link__item tab-btn"
                      >
                        3. 授权
                      </button>
                    </div>
                    <!-- /.benefits-fore__left__tab__link -->
                  </div>
                  <!-- /.benefits-fore__left__tab -->

                  <div class="tabs-content">
                    <div class="item tab active-tab" id="add">
                      <div class="sec-title" data-wow-duration="700ms">
                        <h6 class="sec-title__tagline"></h6>
                        <!-- /.sec-title__tagline -->
                        <h3 class="sec-title__title">手机号注册会员</h3>
                        <!-- /.sec-title__title -->
                      </div>
                      <!-- /.sec-title -->
                      <p class="benefits-fore__text">
                        只须手机号即刻注册会员，简单便捷。没有繁琐的表单填写，无需顾虑个人信息泄露，一个验证码，您就可以享受《小智助播》为您提供的专属服务！
                      </p>
                      <!-- /.benefits-fore__text -->
                      <ul class="benefits-fore__list list-unstyled">
                        <li class="benefits-fore__list__item">会员专属服务</li>
                        <!-- /.benefits-fore__list__item -->
                        <li class="benefits-fore__list__item">
                          QQ客服提供1v1专属服务
                        </li>
                        <!-- /.benefits-fore__list__item -->
                      </ul>
                      <!-- /.benefits-fore__list -->
                    </div>
                    <!-- /.item -->

                    <div class="item tab" id="market">
                      <div class="sec-title" data-wow-duration="700ms">
                        <h6 class="sec-title__tagline"></h6>
                        <!-- /.sec-title__tagline -->
                        <h3 class="sec-title__title">下载小智助播</h3>
                        <!-- /.sec-title__title -->
                      </div>
                      <!-- /.sec-title -->
                      <p class="benefits-fore__text">
                        绿色程序，不用安装，一键运行
                      </p>
                      <!-- /.benefits-fore__text -->
                      <ul class="benefits-fore__list list-unstyled">
                        <li class="benefits-fore__list__item">
                          没有繁琐安装过程
                        </li>
                        <!-- /.benefits-fore__list__item -->
                        <li class="benefits-fore__list__item">
                          绿色软件，没有广告植入
                        </li>
                        <!-- /.benefits-fore__list__item -->
                        <li class="benefits-fore__list__item">方便迁移</li>
                        <!-- /.benefits-fore__list__item -->
                      </ul>
                      <!-- /.benefits-fore__list -->
                    </div>

                    <div class="item tab" id="plan">
                      <div class="sec-title" data-wow-duration="700ms">
                        <h6 class="sec-title__tagline"></h6>
                        <!-- /.sec-title__tagline -->
                        <h3 class="sec-title__title">联网授权，一码搞定</h3>
                        <!-- /.sec-title__title -->
                      </div>
                      <!-- /.sec-title -->
                      <p class="benefits-fore__text">
                        个人中心查看授权信息，购买、续费简单，可用时间一目了然
                      </p>
                      <!-- /.benefits-fore__text -->
                      <ul class="benefits-fore__list list-unstyled">
                        <li class="benefits-fore__list__item">
                          高安全性，防止盗用
                        </li>
                        <!-- /.benefits-fore__list__item -->
                        <li class="benefits-fore__list__item">
                          联网授权，无需担心授权信息丢失
                        </li>
                        <!-- /.benefits-fore__list__item -->
                      </ul>
                      <!-- /.benefits-fore__list -->
                    </div>
                    <!-- /.item -->
                  </div>
                  <!-- /.tabs-content -->
                </div>
                <!-- /.tabs-box -->
                <div class="benefits-fore__box">
                  <h4 class="benefits-fore__box__domine">
                    安全 <br />
                    便捷
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="benefits-fore__right">
                <div class="benefits-fore__thumb__item">
                  <div class="benefits-fore__thumb__item__img">
                    <img
                      src="assets/images/resources/Benefit-4-1.png"
                      alt="benefit image"
                    />
                  </div>
                  <div class="benefits-fore__thumb__item__border">
                    <img
                      src="assets/images/shapes/benefit-4-1.png"
                      alt="benefit image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- benefits-fore section end -->

    <section class="service-details">
      <div class="container">
        <div class="row gutter-y-30">
          <div class="col-md-12 col-lg-4">
            <div class="service-sidebar">
              <div
                class="service-sidebar__single wow fadeInUp"
                data-wow-delay="500ms"
              >
                <h3 class="service-sidebar__title">应用场景</h3>
                <!-- /.service-sidebar__title -->
                <ul class="list-unstyled service-sidebar__nav">
                  <li><span>主播上厕所时</span></li>
                  <li><span class="current">做大场需要人多氛围时</span></li>
                  <li><span>没有场控人员时</span></li>
                  <li><span>主播要跟小助理搭话时</span></li>
                  <li><span>主播对产品不熟悉时</span></li>
                  <li><span>主播需要切换提词卡时</span></li>
                </ul>
                <!-- /.list-unstyled service-sidebar__nav -->
              </div>
              <!-- /.service-sidebar__single -->
            </div>
            <!-- /.sidebar -->
          </div>
          <!-- /.col-md-12 col-lg-4 -->
          <div class="col-md-12 col-lg-8">
            <div class="service-details__content">
              <div class="service-details__single">
                <div
                  class="service-details__thumbnail wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <video autoplay controls src="https://xz-publish.cdn.bcebos.com/应用场景.mp4"></video>
                </div>
                <!-- /.service-details__thumbnail -->
              </div>
              <!-- /.service-details__single-->
            </div>
            <!-- /.service-details__content -->
          </div>
          <!-- /.col-md-12 col-lg-8 -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>
    <!-- /.service-details -->

    <!-- why-choos-fore section start -->
    <section class="testimonial-fore">
      <div
        class="testimonial-fore__bg"
        style="background-image: url(assets/images/shapes/testi-4-1.png)"
      ></div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div
              class="sec-title sec-title--two text-center wow fadeInUp"
              data-wow-duration="700ms"
            >
              <h3 class="sec-title__title">常见问题</h3>
              <!-- /.sec-title__title -->
            </div>
            <!-- /.sec-title -->
          </div>
        </div>
        <div class="testimonial-fore__inner">
          <div
            class="testimonial-fore__inner__bg"
            style="background-image: url(assets/images/shapes/testi-4-1.png)"
          ></div>
          <div class="row">
            <div class="col-12">
              <div class="testimonial-fore__inner">
                <div
                  class="testimonial-fore__inner__bg"
                  style="
                    background-image: url(assets/images/shapes/testi-4-1.png);
                  "
                ></div>
                <div class="row">
                  <div class="col-12">
                    <div
                      class="testimonial-fore__carousel hostom-slick__carousel"
                      data-slick-options='{
                        "slidesToShow": 2,
                        "slidesToScroll": 1,
                        "vertical": true,
                        "asNavFor": ".testimonial-fore__image",
                        "autoplay": true,
                        "dots": true,
                        "centerPadding": 20,
                        "centerMode": true,
                        "arrows": false
                        }'
                    >
                      <div class="item">
                        <div class="testimonial-fore__item">
                          <h4 class="testimonial-fore__item__title">
                            问：《小智助播》软件可以在不同直播间使用吗？
                          </h4>
                          <p class="testimonial-fore__item__text">
                            答：一套《小智助播》只能使用于一个直播账号，不能多个直播账号同时使用。
                          </p>
                          <div class="testimonial-fore__item__quite">
                            <img
                              src="assets/images/shapes/quite4.png"
                              alt="icon"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonial-fore__item">
                          <h4 class="testimonial-fore__item__title">
                            问：同一个关键词可以播放多个录音吗？
                          </h4>
                          <p class="testimonial-fore__item__text">
                            答：可以的。可以为同一个语音关键词上传N条录音，《小智助播》会随机播放其中一条，保持粉丝新鲜感。
                          </p>
                          <div class="testimonial-fore__item__quite">
                            <img
                              src="assets/images/shapes/quite4.png"
                              alt="icon"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="item">
                        <div class="testimonial-fore__item">
                          <h4 class="testimonial-fore__item__title">
                            问：我的产品有很多数据要记住，我应该怎么办？
                          </h4>
                          <p class="testimonial-fore__item__text">
                            答：《小智助播》有提词卡功能哟，一样可以用语音控制切换的哟！
                          </p>
                          <div class="testimonial-fore__item__quite">
                            <img
                              src="assets/images/shapes/quite4.png"
                              alt="icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul
                      class="testimonial-fore__image hostom-slick__carousel"
                      data-slick-options='{
                        "slidesToShow": 2,
                        "slidesToScroll": 1,
                        "autoplay": true,
                        "vertical": true,
                        "centerMode": true,
                        "asNavFor": ".testimonial-fore__carousel",
                        "focusOnSelect": true,
                        "dots": false,
                        "centerPadding": 0,
                        "arrows": false,
                        "responsive": [
                            {
                                "breakpoint": 767,
                                "settings": {
                                    "vertical": false
                                }
                            }
                        ]
                        }'
                    >
                      <!-- Testimonial Item -->
                      <li role="presentation">
                        <div class="testimonial-fore__image__item">
                          <img
                            src="assets/images/team/testi-man-4-3.jpg"
                            alt="hiredots"
                          />
                        </div>
                      </li>
                      <!-- Testimonial Item -->
                      <!-- Testimonial Item -->
                      <li role="presentation">
                        <div class="testimonial-fore__image__item">
                          <img
                            src="assets/images/team/testi-man-4-4.jpg"
                            alt="hiredots"
                          />
                        </div>
                      </li>
                      <!-- Testimonial Item -->
                      <!-- Testimonial Item -->
                      <li role="presentation">
                        <div class="testimonial-fore__image__item">
                          <img
                            src="assets/images/team/testi-man-4-5.jpg"
                            alt="hiredots"
                          />
                        </div>
                      </li>
                      <!-- Testimonial Item -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- why-choos-fore section start -->
    <section class="why-choos-fore">
      <div class="row">
        <div class="col-lg-6">
          <div
            class="why-choos-fore__left wow fadeInLeft"
            data-wow-duration="1000ms"
            data-wow-delay="800ms"
          >
            <div class="why-choos-fore__left__thumb">
              <img
                src="assets/images/resources/why-choose-4-2.jpg"
                alt="image"
              />
            </div>
            <div class="why-choos-fore__left__shape">
              <img src="assets/images/shapes/shape-choos.png" alt />
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="why-choos-fore__right wow fadeInRight"
            data-wow-duration="1000ms"
            data-wow-delay="800ms"
          >
            <div class="why-choos-fore__top">
              <div
                class="sec-title text-start wow fadeInUp"
                data-wow-duration="700ms"
              >
                <h6 class="sec-title__tagline">为什么选择我们</h6>
                <!-- /.sec-title__tagline -->
                <h3 class="sec-title__title">
                  专业的服务团队，专注直播领域多年
                </h3>
                <!-- /.sec-title__title -->
              </div>
              <!-- /.sec-title -->
              <p class="why-choos-fore__text">
                团队服务100+直播项目，在直播领域拥有丰富的业务及技术经验
              </p>
            </div>
            <div class="why-choos-fore__feature">
              <div
                class="why-choos-fore__feature__item why-choos-fore__feature__item--two"
                style="
                  background-image: url(assets/images/resources/why-choos-4-1.png);
                "
              >
                <div class="why-choos-fore__feature__item__funfact count-box">
                  <h3 class="why-choos-fore__feature__item__funfact__count">
                    <span
                      class="why-choos-fore__feature__item__count count-text"
                      data-stop="100"
                      data-speed="2000"
                    ></span>
                    <span>+</span>
                  </h3>
                  <p class="why-choos-fore__feature__item__text">直播项目</p>
                </div>
              </div>
              <div class="why-choos-fore__feature__item">
                <div class="why-choos-fore__feature__item__content">
                  <div class="why-choos-fore__feature__item__icon">
                    <i class="icon-migration-1"></i>
                  </div>
                  <h4 class="why-choos-fore__feature__item__content__title">
                    <a href="about.html">专业运营团队</a>
                  </h4>
                  <p class="why-choos-fore__feature__item__content__text">
                    因为专业，无惧挑战。我们正在成长路上，将为您带来更多功能，敬请期待
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="why-choos-fore__shape">
        <img src="assets/images/shapes/why-choos-4-1.png" alt />
      </div>
    </section>
    <!-- why-choos-fore section start -->

    <MessageBox
      :show="message_show"
      :title="message_title"
      :body="message_content"
      @on-close="onMessageClose"
    ></MessageBox>

    <qr-code
      :show="qrcode_show"
      :url="orderUrl"
      @on-close="onQRCodeClose"
    ></qr-code>

    <MainFooter></MainFooter>
  </div>
</template>

<script>
// @ is an alias to /src
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import MessageBox from "@/components/MessageBox.vue";
import {
  getPayQRCode,
  getProductSpec,
  getChannelProductSpec,
} from "@/api/index";
import { loadExternalJs } from "@/libs/util";
import QrCode from "@/components/QRCode.vue";
import Swal from "sweetalert2";

export default {
  name: "HomeView",
  components: {
    MainHeader,
    MainFooter,
    MessageBox,
    QrCode,
  },
  data() {
    return {
      defaultProductSpecList: [],
      productSpecList: [],
      channelCode: "",
      message_show: false,
      message_title: "",
      message_content: "",
      selectedProductId: "",
      orderUrl: "",
      qrcode_show: false,
      DownloadURL: this.$globalParam.DownloadURL,
    };
  },
  mounted() {
    console.log("Home mounted");
    // 在页面挂载后调用外部js中的方法
    loadExternalJs();
    this.loadData();
  },
  methods: {
    loadData() {
      if (!this.channelCode && this.$route.query && this.$route.query.channel) {
        this.channelCode = this.$route.query.channel;
      }
      getProductSpec().then((res) => {
        if (res && res.success) {
          this.defaultProductSpecList = res.result;
          this.productSpecList = res.result;
        }
      });
    },
    queryChannelProductSpec() {
      try {window.LA.track("EVT_QUERY_CHANNEL_PRICE");} catch (e) {console.error(e)}
      console.log("QueryChannelProductSpec");
      if (this.channelCode) {
        getChannelProductSpec(this.channelCode).then((res) => {
          if (res && res.success) {
            this.productSpecList = res.result;
          } else {
            this.productSpecList = this.defaultProductSpecList;
          }
        });
      } else {
        this.message_show = true;
        this.message_title = "温馨提示";
        this.message_content = "输入渠道可有更优惠的价格哦！";
      }
    },
    onMessageClose(visible) {
      this.message_show = visible;
    },
    onQRCodeClose(visible) {
      this.qrcode_show = visible;
    },
    showPayQRCode() {
      if (this.selectedProductId) {
        try {window.LA.track("EVT_ORDER");} catch (e) {console.error(e)}
        getPayQRCode(
          "",
          this.selectedProductId,
          this.channelCode,
          this.$store.state.user.id,
          this.$store.state.user.openId
        ).then((res) => {
          if (res && res.success) {
            this.qrcode_show = true;
            this.orderUrl = res.result;
          } else {
            Swal.fire({
              title: "二维码获取失败",
              confirmButtonColor: "#f96686",
              confirmButtonText: "好的",
            });
          }
        });
      } else {
        Swal.fire({
          title: "请选择产品规格后再下单",
          confirmButtonColor: "#f96686",
          confirmButtonText: "好的",
        });
      }
    },
    selectProduct(e) {
      if (e && e.target && e.target.dataset) {
        this.selectedProductId = e.target.dataset.id;
        console.log("Selected product: " + this.selectedProductId);
      }
    },
    download(){
      try {window.LA.track("EVT_DOWNLOAD");} catch (e) {console.error(e)}
    }
  },
};
</script>

<style lang="less" scoped>
.retail-price {
  text-decoration: line-through;
  opacity: 0.8;
}
.product-card {
  background-color: #3e3e3e;
  border: 2px solid #3e3e3e;
  border-radius: 15px;
  padding: 10px;
  text-align: center;

  .price {
    margin-top: 4px;
  }
}
.selected-product {
  border: 2px dashed #ffffff;
}

.service-details__thumbnail video{
  border-radius: 40px;
  max-width: 100%;
}
</style>