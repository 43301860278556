/* eslint-disable no-debugger */
import axios from "axios";
import { getStore, setStore } from "./storage";
import router from "../router/index";
import Cookies from "js-cookie";

const axios_instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_API_URL,
  timeout: 15000,
});


const token_name = process.env.VUE_APP_JWT_TOKEN;

// http request 拦截器
axios_instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => {
    return Promise.resolve(err);
  }
);

// http response 拦截器
axios_instance.interceptors.response.use(
  (response) => {
    const data = response.data;
    // 根据返回的code值来做不同的处理(和后端约定), 注意，这里不是处理Http Status Code的地方！
    switch (response.status) {
      case 401:
        // 未登录 清除已登录状态
        Cookies.set("userInfo", "");
        setStore("userInfo", "");
        setStore(token_name, "");
        if (router.history.current.name != "login") {
          router.replace("/login");
        }
        break;
      default:
        return data;
    }
    return data;
  },
  (err) => {
    // 返回状态码不为200时候的错误处理
    if (err.request && err.request.status == 401) {
      if (router.history.current.name != "login") {
        // 未登录 清除已登录状态
        Cookies.set("userInfo", "");
        setStore(token_name, "");
        setStore("userInfo", "");
        router.replace("/login");
      }
    } else {
      console.error("request has error: ", err);
      return Promise.resolve(err);
    }
  }
);

export const getRequest = (url, params) => {
  return axios_instance.get(url, {
    "params": params,
    "headers": {
      [token_name]: getStore(token_name),
    },
  });
};

export const postRequest = (url, params) => {
  return axios_instance.post(url, params, {
    "transformRequest": [
      function (data) {
        let ret = "";
        for (let it in data) {
          ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
        }
        ret = ret.substring(0, ret.length - 1);
        return ret;
      },
    ],
    "headers": {
      "Content-Type": "application/x-www-form-urlencoded",
      [token_name]: getStore(token_name),
    },
  });
};

export const exportRequest = (url, params) => {
  return axios_instance.post(url, params, {
    "headers": {
      [token_name]: getStore(token_name),
    },
  });
};

export const putRequest = (url, params) => {
  return axios_instance.put(url, params, {
    "transformRequest": [
      function (data) {
        let ret = "";
        for (let it in data) {
          ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
        }
        ret = ret.substring(0, ret.length - 1);
        return ret;
      },
    ],
    "headers": {
      "Content-Type": "application/x-www-form-urlencoded",
      [token_name]: getStore(token_name),
    },
  });
};

export const postBodyRequest = (url, params) => {
  return axios_instance.post(url, params, {
    "headers": {
      [token_name]: getStore(token_name),
    },
  });
};
/**
 * 无需token验证的GET请求 避免旧token过期导致请求失败
 * @param {*} url
 * @param {*} params
 */
export const getNoAuthRequest = (url, params) => {
  return axios_instance.get(url, {
    "params": params,
  });
};

export const postNoAuthRequest = (url, params) => {
  return axios_instance.post(url, params, {
    "transformRequest": [
      function (data) {
        let ret = "";
        for (let it in data) {
          ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
        }
        ret = ret.substring(0, ret.length - 1);
        return ret;
      },
    ],
    "headers": {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const postBodyNoAuthRequest = (url, params) => {
  return axios_instance.post(url, params);
};

export const uploadFileRequest = (url, key, file) => {
  if (!key) {
    key = "file";
  }
  const data = new FormData();
  data.append(key, file);
  return axios_instance.post(url, data, {
    "headers": {
      "Content-Type": "multipart/form-data",
    },
  });
};
