<template>
  <header
    class="main-header main-header--fore sticky-header sticky-header--normal"
  >
    <div class="container-fluid">
      <div class="main-header__inner">
        <!-- <div class="main-header__logo">
          <a href="index.html">
            <img
              src="assets/images/logo-dark-2.png"
              alt="hostom HTML"
              width="158"
            />
          </a>
        </div> -->
        <!-- /.main-header__logo -->

        <nav class="main-header__nav main-menu">
          <ul class="main-menu__list">
            <li>
              <router-link to="/">首页</router-link>
            </li>
            <li>
              <a :href="DownloadURL" target="_blank" @click="download"
                >程序下载</a
              >
            </li>
            <li v-if="Logined">
              <router-link to="/my-license">我的授权码</router-link>
            </li>
            <li v-if="Logined">
              <router-link to="/my-order">我的订单</router-link>
            </li>
            <li v-if="Logined && IsPartner">
              <router-link to="/channel-center">我是渠道商</router-link>
            </li>
          </ul>
        </nav>
        <!-- /.main-header__nav -->

        <div class="main-header__right">
          <div class="main-header__right__inner">
            <a href="tel:+8801775-338747" class="info__item__call">
              <div class="info__item__icon">
                <i class="icon-call-2"></i>
              </div>
              <div class="info__item__content">
                <span class="info__item__content__text">咨询QQ</span>
                <h6 class="info__item__content__call">{{ ConsultQQ }}</h6>
              </div>
            </a>

            <!-- /.main-header__right-->
            <div
              v-if="!InLoginPage && !Logined"
              class="main-header__right__info"
            >
              <router-link to="login" class="info__item"
                ><i class="icon-user" aria-hidden="true"></i
                ><span>&nbsp;立即登录</span></router-link
              >
            </div>
            <div v-if="Logined" class="main-header__right__info">
              <span to="login" class="info__item"
                ><span @click="logout">退出</span></span
              >
            </div>
          </div>
          <!-- /.main-header__right__item -->
          <div class="mobile-nav__btn mobile-nav__toggler">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <!-- /.mobile-nav__toggler -->
        </div>
        <!-- /.main-header__right -->
      </div>
      <!-- /.main-header__inner -->
    </div>
    <!-- /.container-fluid -->
  </header>
  <!-- /.main-header -->
</template>

<script>
export default {
  name: "main-header",
  props: {},
  data() {
    return {
      ConsultQQ: this.$globalParam.ConsultQQ,
      DownloadURL: this.$globalParam.DownloadURL,
      InLoginPage: false,
      Logined: false,
      IsPartner: false,
      NickName: "",
    };
  },
  methods: {
    logout() {
      this.$store.commit("logout");
      this.Logined = false;
      this.$router.replace("/");
    },
    download() {
      try {window.LA.track("EVT_DOWNLOAD");} catch (e) {console.error(e)}
    },
  },
  watch: {},
  mounted() {
    this.InLoginPage = this.$route.name == "login";
    this.Logined = this.$store.state.user.logined;
    this.IsPartner = this.$store.state.user.partnerId != "";
    this.NickName = this.$store.state.user.nickName;
  },
};
</script>
