<template>
  <div v-if="visible" :class="modal_class">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- 模态标题 -->
        <div class="modal-header">
          <h4 class="modal-title">{{ title }}</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            @click="close"
          ></button>
        </div>

        <!-- 模态主体 -->
        <div class="modal-body">{{ body }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "message-box",
  props: {
    title: {
      type: String,
      default: "系统提示",
    },
    body: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      modal_class: "modal fade",
    };
  },
  watch: {
    show(newVal){
      this.visible = newVal;
      if (newVal) {
        this.modal_class = "modal show";
      } else {
        this.modal_class = "modal fade";
      }
    }
  },
  mounted() {
    // this.modal_class = "modal show";
  },
  methods: {
    close() {
      this.visible = false;
      this.$emit("on-close", this.visible);
    },
  }
};
</script>

<style scoped>
  .show{
    display: block
  }
</style>
