<template>
  <div v-if="visible" :class="modal_class">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- 模态标题 -->
        <div class="modal-header">
          <h4 class="modal-title">扫码下单</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            @click="close"
          ></button>
        </div>

        <!-- 模态主体 -->
        <div class="modal-body center">
          <canvas ref="qrcodeCanvas"></canvas>
          <div class="tips">微信扫码立即下单支付</div>
          <router-link to="/my-license" class="tips">我已成功支付</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCodeCreator from "qrcode";
export default {
  name: "QRCode",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      modal_class: "modal fade",
    };
  },
  watch: {
    show(newVal) {
      this.visible = newVal;
      if (newVal) {
        this.modal_class = "modal show";
        this.generateQRCode();
      } else {
        this.modal_class = "modal fade";
      }
    },
    url(newVal) {
      if (newVal) {
        this.generateQRCode();
      }
    },
  },
  mounted() {},
  methods: {
    generateQRCode() {
      if (this.visible && this.url) {
        console.log("generateQRCode:", this.url);
        this.$nextTick(() => {
          const canvas = this.$refs.qrcodeCanvas;
          QRCodeCreator.toCanvas(canvas, this.url, { width: 320 }, (error) => {
            if (error) console.error(error);
          });
        });
      }
    },
    close() {
      this.visible = false;
      this.$emit("on-close", this.visible);
    },
  },
};
</script>

<style scoped>
.show {
  display: block;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.tips {
  font-size: 22px;
  text-align: center;
  margin: 5px 0;
  color: red;
}
</style>