import Cookies from "js-cookie";
import { getStore, setStore } from "@/libs/storage";
export const token_name = process.env.VUE_APP_JWT_TOKEN;
const user = {
  state: {
    id: "",
    logined: false,
    nickName: "",
    openId: "",
    partnerId: "",
  },
  mutations: {
    loadUserStateFromStorage(state) {
      let userInfoJson = getStore("userInfo");
      if (userInfoJson) {
        let userInfo = JSON.parse(userInfoJson);
        if (userInfo) {
          state.id = userInfo.id;
          state.nickName = userInfo.nickName;
          state.logined = true;
          state.openId = userInfo.openId;
          state.partnerId = userInfo.partnerId;
        }
      }
    },
    login(state, userInfo) {
      if (userInfo) {
        state.id = userInfo.id;
        state.nickName = userInfo.nickName;
        state.logined = true;
        state.openId = userInfo.openId;
        state.partnerId = userInfo.partnerId;
        setStore("userInfo", userInfo);
      }
    },
    logout(state) {
      state.id = "";
      state.logined = false;
      state.nickName = "";
      state.openId = "";
      state.partnerId = "";
      Cookies.remove("userInfo");
      Cookies.remove(token_name);
      localStorage.clear();
    },
  },
};

export default user;
