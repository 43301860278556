import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        // 状态
        // 调用方法：this.$store.state.[模块名].状态项
    },
    mutations: {
        // 改变方法
        // 调用方法：this.$store.commit("方法名", 参数)
    },
    actions: {

    },
    modules: {
        user
    }
});

export default store;
